<template>
	<div class="card">
    <div class="card-body">
      <div class="row"><div class="col"><h4><span class="bg-secondary"># {{$t('report.user_working_date_time')}}</span></h4></div></div>
      <div class="row d-flex justify-content-between align-items-center">
        <div :class="{'col-md-3': is_admin && cocon && this.ls_branch_ddl.length>0,'':!(is_admin && cocon && this.ls_branch_ddl.length>0)}" v-if="is_admin && cocon && this.ls_branch_ddl.length>0">
          <label for="validationBranch">{{$t('common.branch')}}</label>
          <multiselect v-model="branchs" :tag-placeholder="$t('common.select_branch')"
            :placeholder="$t('common.placeholder_branch')" label="name" :select-label="$t('common.enter_to_select')"
            track-by="id" :options="ls_branch_ddl" :multiple="true"
            :taggable="true" :deselect-label="$t('common.enter_to_remove')"></multiselect>
        </div>

        <div :class="{'col-md-3': is_admin && cocon && this.ls_branch_ddl.length>0,'col-md-4':!(is_admin && cocon && this.ls_branch_ddl.length>0)}">
          <label for="validationNgayFromTo">{{$t('common.from_date')}}</label>
          <date-picker v-model="search_form.tungay" :config="options" class="date-picker-height"></date-picker>
        </div>
        <div :class="{'col-md-3': is_admin && cocon && this.ls_branch_ddl.length>0,'col-md-4':!(is_admin && cocon && this.ls_branch_ddl.length>0)}">
          <label for="validationNgayFromTo">{{$t('common.to_date')}}</label>
          <date-picker v-model="search_form.denngay" :config="options" class="date-picker-height"></date-picker>
        </div>
        <div class="col-md-3 pull-right">
          <a href="#" @click="apply_filter" class="btn btn-primary mr-1"
          style="margin-top: 20px;" id="validationSubmit"><i class="fa fa-search" aria-hidden="true"></i> {{$t('common.search')}}</a>
          <a href="#" @click="export_pdf()" class="btn btn-danger mr-1" v-b-modal.pdf_user_working_review_screen
          style="margin-top: 20px;" id="validationSubmit"><i class="fa fa-file-pdf-o" aria-hidden="true"></i> Pdf</a>
          <!-- <a href="#" @click="export_excel()" class="btn btn-warning"
          style="margin-top: 20px;" id="validationSubmit"><i class="fa fa-file-excel-o" aria-hidden="true"></i> Excel</a> -->
        </div>
      </div>
      <div class="row mt-2">
        <div class="col">
          <b-table striped hover responsive
            :items="filteredAndSortedData_inout" :fields="fields" :current-page="page"
            :per-page="0"  @row-clicked="rowClicked">
          <template slot="top-row" slot-scope="{ fields }">
            <td v-for="field in fields" :key="field.key">
              <input v-model="filteredAndSortedData[field.key]" 
              v-if="field.key && field.label" :placeholder="field.label">
            </td>
          </template>
            <template slot="created_at" slot-scope="data">
              <label v-if="data.item.created_at">{{VnDateTimeFormat(data.item.created_at)}}</label>
              <label v-else></label>
            </template>
            <template slot="type" slot-scope="data">
              <label v-if="data.item.type">{{$t("common.intime")}}</label>
              <label v-else>{{$t("common.outtime")}}</label>
            </template>
            <template slot="company" slot-scope="data">
              <label v-if="data.item.branch">{{data.item.branch}}</label>
              <label v-else>{{data.item.company}}</label>
            </template>
            <template slot="des" slot-scope="data">
              <label v-if="data.item.des">{{JSON.parse(data.item.des)[$i18n.locale]}}</label>
              <label v-else></label>
            </template>
          </b-table>
        </div>
      </div>

      <div class="row pull-right">
        <div class="col">
          <pager :frame="8"
                :pageCount="page_count"
                :page="page"
                @change="change_page">
          </pager>
        </div>
      </div>
    </div>
    <b-modal id="pdf_user_working_review_screen" :title="$t('common.report')" size="lg" header-bg-variant="info" no-body ok-only>
      <div id="pdf_user_working" style="height:450px;"></div>
    </b-modal>
	</div>
</template>
<style scoped>
  label{
    margin-bottom: 0rem;
  }
  input{
    min-height: 43px;
  }
</style>
<script>
import pdfobj from 'pdfobject'
import { required} from 'vuelidate/lib/validators'
import Loading from 'vue-loading-overlay'
import datePicker from 'vue-bootstrap-datetimepicker'
import {Role} from '../../helpers/role'
import moment from 'moment'
import Multiselect from 'vue-multiselect'
import { mapGetters, mapActions } from 'vuex'
import Pager from '../Pager'

export default {
	components: {Loading,datePicker,Multiselect,Pager},
	data () {
		return {
			search_form:{
        company_id:null,
        company_cocon:false,
				ls_branch:[],
				tungay:moment().clone().startOf('month').format('DD-MM-YYYY'),
        denngay:moment().format('DD-MM-YYYY'),
			},
			options: {
				format: 'DD-MM-YYYY',
				useCurrent: false,
				showClear: true,
        showClose: true
			}
		}
	},
	validations: {
    search_form: {
      tungay: {
        required
      },
      denngay: {
        required
      }
    }
  },
	computed: {
    ...mapGetters('st_inout', ['ls_inout','from_date','to_date','branchs','company_id','page_count','page','per_page','has_son']),
    ...mapGetters('st_branch', ['ls_branch_ddl']),
    is_admin () {
      let user= this.$store.state.st_authentication.user
      return user&& user.role==Role.Admin
    },
    is_super () {
      let user= this.$store.state.st_authentication.user
      return user&& user.role==Role.Super_Admin
    },
    is_branch () {
      let user= this.$store.state.st_authentication.user
      return user&& user.role==Role.Branch_Admin
    },
    cocon () {
      let user= this.$store.state.st_authentication.user
      return Boolean(user.company.cocon)
    },
    business () {
      let user= this.$store.state.st_authentication.user
      return user&&user.company.business
    },
    user_login () {
      return this.$store.state.st_authentication.user
    },
    ls_branch_ddl(){
      return this.$store.state.st_branch.ls_branch_ddl
    },
    fields(){
      let fields=[]
      let user=this.$store.state.st_authentication.user
      fields.push({key: 'company',label:this.$t('common.company_branch'),sortable: true, variant:'info'})

      if(this.is_super){
        fields.push({key: 'business',label:this.$t('common.business'),sortable: true})
      }

      fields.push({key: 'user',label:this.$t('common.user'),sortable: true})

      fields.push({key: 'type',label:this.$t('common.type'),sortable: true})
      fields.push({key: 'des',label:this.$t('common.description'),sortable: true})
      fields.push({key: 'created_at',label:this.$t('common.date'),sortable: true})

      return fields
    },

    from_date:{
      get: function(){
        return this.$store.state.st_inout.from_date
      },
      set: function(date){
        this.$store.commit('st_inout/set_from_date',date)
      }
    },

    to_date:{
      get: function(){
        return this.$store.state.st_inout.to_date
      },
      set: function(date){
        this.$store.commit('st_inout/set_to_date',date)
      }
    },

    branchs:{
      get: function(){
        return this.$store.state.st_inout.branchs
      },
      set: function(ls){
        this.$store.commit('st_inout/set_branchs',ls)
      }
    },

    company_id:{
      get: function(){
        return this.$store.state.st_inout.company_id
      },
      set: function(id){
        this.$store.commit('st_inout/set_company_id',id)
      }
    },
		ls_branch_ddl(){
      return this.$store.state.st_branch.ls_branch_ddl
		},

    has_son:{
      get: function(){
        return this.$store.state.st_inout.has_son
      },
      set: function(flag){
        this.$store.commit('st_inout/set_has_son',flag)
      }
    },

    filteredAndSortedData_inout:{
      get: function(){
        let vm=this
        let result = vm.$store.state.st_inout.ls_inout
        return result
      }
    }
  },
	mounted(){
    let vm=this
    vm.company_id=vm.user_login.company_id
    vm.has_son=vm.user_login.company.cocon
    vm.from_date=vm.search_form.tungay
    vm.to_date=vm.search_form.denngay
    debugger
    if(vm.user_login.role==Role.Branch_Admin){
      vm.branchs=[{id:vm.user_login.branch_id}]
    }
		if(vm.is_admin && vm.user_login.company.cocon){
			vm.get_ls_branch_ddl(vm.user_login.company_id)
    }
    vm.get_ls_inout()
	},
	methods: {
    ...mapActions('st_inout', ['get_ls_inout','apply_filter','change_page']),
    ...mapActions('st_branch', ['get_ls_branch_ddl']),
    rowClicked (item) {},
		export_pdf() {
      var app = this
      app.from_date=app.search_form.tungay
      app.to_date=app.search_form.denngay
      app.$store.dispatch('st_inout/pdf_user_working',app.$i18n.locale).then((response) => {
        if(response && response.data){
          let blob = new Blob([response.data], { type: 'application/pdf' })
          pdfobj.embed(window.URL.createObjectURL(blob), "#pdf_user_working")
        }
      })
    },
    export_excel(){
      var app = this
      app.$store.dispatch('st_inout/excel_user_working').then((response) => {
        if(response && response.data){
          const url = URL.createObjectURL(new Blob([response.data], {
              type: 'application/vnd.ms-excel'
          }))
          const link = document.createElement('a')
          link.href = url
          link.setAttribute('download', 'Báo cáo tồn kho')
          document.body.appendChild(link)
          link.click()
        }
      })
    }
	}
}
</script>

<style scoped>
	.pdfobject-container { width: 100%; height: 80rem; border: 1rem solid rgba(0,0,0,.1); }
</style>
